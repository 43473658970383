import axios from 'axios';
import store from '../../store';

const domainAPI = store.state.domainAPI;

// Get all staging request
const adm_stagingService_GetAllSR = (data) =>
  axios.get(domainAPI + '/api/staging-request', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      includes:
        'requestItems.additionalServices.service,billing,user.userProfile,requestItems.corrections',
      single_status: data.status,
      page: data.page,
      search: data.search
    },
  });

// Get all staging request
const adm_stagingService_GetSRData = (data) =>
  axios.get(domainAPI + `/api/staging-request/${data.id}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
    },
  });

// Get all staging request
const adm_stagingService_GetSRItems = (data) =>
  axios.get(domainAPI + `/api/staging-request/${data.id}/items`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      includes:
        'roomArea,furnitures.furniture,additionalOptions.additionalOption,corrections',
      paginated: data.paginated,
      page_size: data.paginated,
      page: data.page,
    },
  });

// Get all request items for approval
const adm_stagingService_GetSRItemsForApproval = (data) =>
axios.get(domainAPI + `/api/staging-request/${data.id}/approval_items`, {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
  params: {
    includes:
      'roomArea,furnitures.furniture,additionalOptions.additionalOption,corrections',
    paginated: data.paginated,
    page_size: data.paginated,
    page: data.page,
  },
});

// correction staging request
const adm_stagingService_CreateCR = (data) =>
  axios.post(
    domainAPI + '/api/admin/correction-request',
    {
      items: data.items,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// Get all staging request By customer Id
const adm_stagingService_GetAllSrByCustomerID = (data) =>
  axios.get(
    domainAPI + `/api/staging-request/customer/${data.customer_id}`,
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
      params: {
        includes:
          'requestItems.additionalServices.service,billing,user.userProfile',
        page: data.page,
      },
    }
  ); 
// Get all coupon request
const adm_stagingService_GetCoupon = (data) =>
axios.get(domainAPI + '/api/coupons', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
  params: {
    page: data.page,
    search: data.search
  },
});
// Get coupon detail
const adm_stagingService_GetCouponDetailsByID = (data) =>
  axios.get(
    domainAPI + `/api/coupons/${data.id}`, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// correction staging request
const adm_stagingService_UpdateCouponDetail = (data) =>
  axios.put(
    domainAPI + `/api/coupons/${data.id}`,
    {
      name: data.name,
      type: data.type,
      code: data.code,
      available_count: data.available_count,
      amount: data.amount,
      start_at: data.start_at,
      end_at: data.end_at
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

// correction staging request
const adm_stagingService_CreateCouponDetail = (data) =>
axios.post(
  domainAPI + `/api/coupons/`,
  {
    name: data.name,
    type: data.type,
    code: data.code,
    available_count: data.available_count,
    amount: data.amount,
    start_at: data.start_at,
    end_at: data.end_at
  },
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
);

// correction staging request
const adm_stagingService_DeleteCoupon = (data) =>
axios.delete(
  domainAPI + `/api/coupons/${data.id}`,
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
);

const adm_inquiryService_getInquiry = (data) =>
axios.get(domainAPI + '/api/inquiry', {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
  params: {
    page: data.page,
  },
});

const adm_inquiryService_getInquiryByID = (data) =>
  axios.get(domainAPI + '/api/inquiry/' + data.id, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

const adm_invoicePayment_GetList = (data) =>
axios.get(
  domainAPI + '/api/billing/invoice-payment/list/',
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      page: data.page,
      searchDate: data.searchDate,
    },
  }
);

const adm_invoicePayment_GetDetail = (data) =>
axios.get(
  domainAPI + `/api/billing/invoice-payment/${data.id}`,
  {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  }
);

const adm_invoicePayment_GetSRData = (data) =>
axios.get(domainAPI + `/api/billing/invoice-payment/view/${data.id}`, {
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
  params: {
  },
});
// correction staging request
const adm_stagingService_ChangeTime = (data) =>
axios.get(
  domainAPI + `/api/admin/change-time/`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    params: {
      searchId: data.searchId
    },
  }
);

const adm_cancel_completion = (data) =>
axios.post(domainAPI + '/api/admin/staging-request/cancel-confirmed', 
{
  stagingId: data.stagingId,
},
{
  headers: {
    Authorization: `Bearer ${data.token}`,
  },
});
export {
  adm_stagingService_GetAllSR,
  adm_stagingService_GetSRData,
  adm_stagingService_GetSRItems,
  adm_stagingService_CreateCR,
  adm_stagingService_GetAllSrByCustomerID,
  adm_stagingService_GetSRItemsForApproval,
  adm_invoicePayment_GetList,
  adm_invoicePayment_GetSRData,
  adm_invoicePayment_GetDetail,
  adm_stagingService_GetCoupon,
  adm_stagingService_GetCouponDetailsByID,
  adm_stagingService_UpdateCouponDetail,
  adm_stagingService_CreateCouponDetail,
  adm_stagingService_DeleteCoupon,
  adm_stagingService_ChangeTime,
  adm_inquiryService_getInquiry,
  adm_inquiryService_getInquiryByID,
  adm_cancel_completion
};
