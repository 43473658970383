<template>
  <div class="col-12" ref="details-wrapper">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-9">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            <h4 class="blue-text font-weight-bold">
              <font-awesome-icon
                @click="$router.go(-1)"
                class="mr-3"
                :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
              />
              <span class="details-title">依頼詳細を表示</span>
            </h4>
          </div>
          <div
            class="col-12 col-md-12 col-lg-12 col-xl-6"
            v-if="pagination.data"
          >
            <span class="blue-text float-right">
              {{ pagination.data.current_page }}/{{ pagination.data.last_page }}
              枚
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="listingDetailsData">
      <div id="uploaded-image" class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row">
            <div class="col-12">
              <p class="blue-text font-weight-bold">
                <span class="details-title">アップロードした画像</span>
              </p>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <div class="image-wrapper pb-5">
                <div class="image">
                  <img
                    :src="listingDetailsData.file_url"
                    onerror="this.src='/img/image-error.png'"
                    @click="openModal(listingDetailsData.file_url)"
                  />
                </div>
                <div class="details-date">
                  <p class="timestamp">
                    <span class="font-weight-bold mr-2">更新日時:</span>
                    <span>
                      {{ formatDate(stagingListing.updated_at) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <div class="details">
                <div class="mb-2">
                  <p class="font-weight-bold m-0">画像詳細</p>
                  <p>
                    <span>画像名:</span>
                    <span>
                      {{
                        listingDetailsData.filename +
                        '.' +
                        listingDetailsData.file_extension
                      }}
                    </span>
                  </p>
                </div>
                <div class="mb-2">
                  <p>
                    お部屋の種類:
                    {{
                      listingDetailsData.room_area
                        ? listingDetailsData.room_area.name
                        : ''
                    }}
                  </p>
                </div>
                <div
                  class="mb-2"
                  v-if="listingDetailsData.furnitures.length > 0"
                >
                  <p>
                    <span>家具:</span>
                    <span
                      v-for="(el, i) in listingDetailsData.furnitures"
                      :key="i"
                    >
                      <span v-if="el.furniture">
                        {{
                          listingDetailsData.furnitures.length !== i + 1
                            ? el.furniture.name + ','
                            : el.furniture.name
                        }}
                      </span>
                    </span>
                  </p>
                </div>
                <div
                  class="mb-2"
                  v-if="listingDetailsData.additional_options.length > 0"
                >
                  <p>
                    <span>追加オプション:</span>
                    <span
                      v-for="(el, i) in listingDetailsData.additional_options"
                      :key="i"
                    >
                      {{
                        listingDetailsData.additional_options.length !== i + 1
                          ? el.additional_option.name + ','
                          : el.additional_option.name
                      }}
                    </span>
                  </p>
                </div>
                <div class="mb-2" v-if="listingDetailsData.remarks">
                  <p>
                    ユーザーからのコメント:
                    {{
                      listingDetailsData.remarks
                        ? listingDetailsData.remarks
                        : ''
                    }}
                  </p>
                </div>
                <div class="mb-2" v-if="listingDetailsData.revisions.length > 0">
                  <p>
                    styledod社への不承認コメント:
                    <span
                      v-for="(el, i) in listingDetailsData.revisions"
                      :key="i"
                    >
                      {{
                        el
                          ? el.message
                          : ''
                      }}
                    </span>
                  </p>
                </div>
                <div class="mb-2" v-if="listingDetailsData.corrections.length > 0">
                  <p>
                    ユーザーからの修正コメント:
                    <span
                      v-for="(el, i) in listingDetailsData.corrections"
                      :key="i"
                    >
                      {{
                        el
                          ? el.message
                          : ''
                      }}
                    </span>
                  </p>
                </div>
                <div class="mb-2">
                  <p>
                    注文カテゴリ:
                    <span v-if="stagingListing.request_category == 1">
                      デジタルステージング
                    </span>
                    <span v-if="stagingListing.request_category == 2">
                      360°デジタルステージング
                    </span>
                    <span v-if="stagingListing.request_category == 3">
                      家具消し
                    </span>
                    <span v-if="stagingListing.request_category == 4">
                      商業用デジタルステージング
                    </span>
                  </p>
                </div>
                <div class="mb-2">
                  <p>
                    部屋のスタイル:
                    <span>
                      {{
                        stagingListing.style
                          ? stagingListing.style.name
                          : ''
                      }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-5" />

      <div v-for="(revision, j) in listingDetailsData.revisions" :key="j" class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row">
            <div class="col-12">
              <p class="blue-text font-weight-bold">
                <span class="details-title">管理側修正対応 {{j + 1}}回目</span>
              </p>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <div class="image-wrapper pb-5">
                <div class="image">
                  <img
                    :src="revision.result_file_url"
                    onerror="this.src='/img/image-error.png'"
                    @click="openModal(revision.result_file_url)"
                  />
                </div>
                <div class="details-date">
                  <p class="timestamp">
                    <span class="font-weight-bold mr-2">更新日時:</span>
                    <span>
                      {{ formatDate(revision.updated_at) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <div class="details">
                <div class="mb-2">
                  <p class="font-weight-bold m-0">画像詳細</p>
                  <p>
                    <span>画像名:</span>
                    <span>
                      {{
                        revision.result_file_name +
                        '.' +
                        revision.result_file_extension
                      }}
                    </span>
                  </p>
                </div>
                <div class="mb-2">
                  <p>
                    コメント:
                    <span>
                      {{
                        revision.message
                          ? revision.message
                          : ''
                      }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="listingDetailsData.revisions.length > 0" class="mt-5" />

      <div v-for="(correction, t) in listingDetailsData.corrections" :key="t" class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row">
            <div class="col-12">
              <p class="blue-text font-weight-bold">
                <span class="details-title">ユーザ側修正対応 {{t + 1}}回目</span>
              </p>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <div class="image-wrapper pb-5">
                <div class="image">
                  <img
                    :src="correction.result_file_url"
                    onerror="this.src='/img/image-error.png'"
                    @click="openModal(correction.result_file_url)"
                  />
                </div>
                <div class="details-date">
                  <p class="timestamp">
                    <span class="font-weight-bold mr-2">更新日時:</span>
                    <span>
                      {{ formatDate(correction.updated_at) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6">
              <div class="details">
                <div class="mb-2">
                  <p class="font-weight-bold m-0">画像詳細</p>
                  <p>
                    <span>画像名:</span>
                    <span>
                      {{
                        correction.result_file_name +
                        '.' +
                        correction.result_file_extension
                      }}
                    </span>
                  </p>
                </div>
                <div class="mb-2">
                  <p>
                    コメント:
                    <span>
                      {{
                        correction.message
                          ? correction.message
                          : ''
                      }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr v-if="listingDetailsData.corrections.length > 0" class="mt-5" />

      <div id="uploaded-image" class="row" v-if="current_version">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row">
            <div class="col-12">
              <p class="blue-text font-weight-bold">
                <span class="details-title">ステージング画像</span>
              </p>
            </div>
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-6"
              v-if="current_version"
            >
              <p class="font-weight-bold">
                <span class="details-title">現在のバージョン</span>
              </p>
              <div class="image-wrapper pb-3">
                <div class="image">
                  <img
                    :src="current_version.result_file_url"
                    onerror="this.src='/img/image-error.png'"
                    @click="openModal(current_version.result_file_url)"
                  />
                  <a
                    href="#"
                    @click.prevent="
                      downloadItem(
                        current_version.result_file_path,
                        `SR-${listingDetailsData.id}_current_version_${current_version.delivery_date}`
                      )
                    "
                  >
                    <div class="download-wrapper float-right">
                      <font-awesome-icon
                        :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                        :style="{
                          color: '#034EA1',
                          height: '36px',
                          width: '36px',
                          top: '-60px',
                        }"
                      />
                    </div>
                  </a>
                </div>
                <div class="details-date">
                  <p class="timestamp">
                    <span class="font-weight-bold mr-2">納品日時:</span>
                    <span class="font-weight-bold">
                      {{ formatDate(stagingListing.delivery_date) }}
                    </span>
                  </p>
                  <p class="timestamp">
                    <span class="font-weight-bold mr-2">確認期限:</span>
                    <span class="font-weight-bold">
                      {{ formatDate(stagingListing.deadline_date) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-12 col-lg-12 col-xl-6"
              v-if="old_versions.length > 0"
            >
              <p class="font-weight-bold">
                <span class="details-title">以前のバージョン</span>
              </p>
              <div class="image-wrapper pb-5">
                <div class="image">
                  <b-carousel
                    class="img-carousel"
                    :interval="0"
                    :controls="old_versions.length > 1 ? true : false"
                    :indicators="old_versions.length > 1 ? true : false"
                  >
                    <b-carousel-slide v-for="(el, i) in old_versions" :key="i">
                      <template #img>
                        <img
                          :src="el.result_file_url"
                          onerror="this.src='/img/image-error.png'"
                          @click="openModal(el.result_file_url)"
                        />
                      </template>
                      <a
                        href="#"
                        @click.prevent="
                          downloadItem(
                            el.result_file_path,
                            `SR-${listingDetailsData.id}_${el.delivery_date}`
                          )
                        "
                      >
                        <div
                          class="download-wrapper float-right old-version-carousel"
                        >
                          <font-awesome-icon
                            :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                            :style="{
                              color: '#034EA1',
                              height: '36px',
                              width: '36px',
                              top: '-60px',
                            }"
                          />
                        </div>
                      </a>
                    </b-carousel-slide>
                  </b-carousel>
                </div>
                <div class="details-date">
                  <p class="timestamp">
                    <span class="font-weight-bold mr-2">納品日時:</span>
                    <span class="font-weight-bold">
                      {{ formatDate(stagingListing.delivery_date) }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination
        class="mt-5 mb-0"
        :data="pagination.data"
        :limit="pagination.limit"
        :show-disabled="pagination.showDisabled"
        :size="pagination.size"
        :align="pagination.align"
        @pagination-change-page="getResults"
      />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row mt-4">
            <div class="col-12 mb-3">
              <b-skeleton width="20%"></b-skeleton>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <b-skeleton-img width="100%"></b-skeleton-img>
              <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <div class="details">
                <div class="mb-3">
                  <b-skeleton width="80%"></b-skeleton>
                </div>
                <div class="mb-3">
                  <b-skeleton width="50%"></b-skeleton>
                </div>
                <div class="mb-3">
                  <b-skeleton width="100%"></b-skeleton>
                </div>
                <div class="mb-3">
                  <b-skeleton width="60%"></b-skeleton>
                </div>
                <div class="mb-3">
                  <b-skeleton width="90%"></b-skeleton>
                </div>
                <div class="mb-3">
                  <b-skeleton width="40%"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <img :src="modalImageUrl">
    </image-modal>
  </div>
</template>

<script>
// Import Services
import { globalService_DownloadIMG } from '../../services/global';
import { adm_stagingService_GetSRItems, adm_stagingService_GetSRData } from '../../services/admin/staging';

// Import Moment JS
import moment from 'moment';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice } from '../../common/Utility';

// Import Vuex/Store
import { mapGetters } from 'vuex';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | View',
    };
  },
  data() {
    return {
      stagingListing: null,
      listingDetailsData: null,
      old_versions: [],
      current_version: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'left',
      },
      isMix:false,
      isOpenModal: false,
      modalImageUrl: null,
    };
  },

  watch: {
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);

      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
  },
  computed: {
    ...mapGetters(['currentRoute']),
  },

  components: {
    'image-modal': ImageModal,
  },

  mounted() {
    this.setListingDetailsData();
  },

  methods: {
    formatDate,
    formatPrice,
    setListingDetailsData() {
      let _this = this;
      _this.getResults();
    },
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    closeModal(){
      this.isOpenModal = false;
    },

    getResults(page) {
      let _this = this;

      if (!page) {
        page = 1;
      }

      let stagingRequestID = _this.$route.params.id;
      let accessToken = localStorage.getItem('_accessToken');

      _this.stagingListing = null;
      _this.listingDetailsData = null;

      adm_stagingService_GetSRData({
        id: stagingRequestID,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.stagingListing = data;
        })
        .catch((error) => {
          console.log(error);
          _this.$bvToast.toast(
            'データの取得中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );
        });

      adm_stagingService_GetSRItems({
        id: stagingRequestID,
        paginated: 1,
        page_size: 1,
        page: page ? page : 1,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log('test');
          console.log(data);
          if (data.data.length > 0) {
            _this.listingDetailsData = data.data[0];
            _this.listingDetailsData.corrections = _this.listingDetailsData.corrections.slice().reverse();
            _this.listingDetailsData.revisions = _this.listingDetailsData.revisions.slice().reverse();
            _this.pagination.data = data;

            //get staging versions
            const { current_version, prev_versions } = getRequestItemVersions(
              data.data[0]
            );
            _this.current_version = current_version;
            _this.old_versions = prev_versions;
          }
        })

        .catch((error) => {
          console.log(error);
        });

      _this.$store.state.currentPaginationPage = page;

      //scroll view on top
      var detailsWrapper = _this.$refs['details-wrapper'];
      if (detailsWrapper) {
        detailsWrapper.scrollIntoView({ block: 'center', inline: 'nearest' });
      }
    },

    downloadItem(path, filename) {
      let accessToken = localStorage.getItem('_accessToken');

      globalService_DownloadIMG({
        token: accessToken,
        path: path,
        isMix:this.isMix,
      }).then((response) => {
        let url = window.URL.createObjectURL(response.data);
        let link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);

        link.click();
      });
    },
  },
};
</script>

<style scoped>
.main-content {
  padding: 110px 60px 150px 350px;
}
.details-title {
  font-size: 19px;
  font-style: normal normal medium 19px/23px Roboto;
}
.details-title {
  margin-bottom: 43px;
}
.details span,
.details p {
  font-size: 16px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}

#uploaded-image {
  margin-top: 40px;
}

#uploaded-image .image {
  height: 340px;
  background: #ececec;
  border-radius: 15px;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1440px) {
  #uploaded-image .image {
    height: 300px;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .image {
    height: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #uploaded-image .image {
    height: 250px;
  }
}

#uploaded-image .image img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}

#uploaded-image .image .img-carousel {
  height: 100%;
  border-radius: 15px;
  background: #e9e9e9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#uploaded-image .timestamp {
  margin: 10px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .timestamp {
    margin: 10px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .details {
    margin-top: 60px;
  }
}

.download-wrapper {
  position: absolute;
  right: 36px;
  bottom: 12px;
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.download-wrapper.old-version-carousel {
  right: -40px;
  bottom: -8px;
}

.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}
</style>