/**
 * Get Request Item Staging Image Versions
 * @param {Array(data)}
 * @returns {array}
 */
export const getRequestItemVersions = (data) => {
  let ver = [];
  let current_version = null;
  let prev_versions = [];
  //get versions
  if (data.corrections) {
    ver = data.corrections.concat(data);
  } else {
    ver.push(data);
  }

  ver = ver.filter((req) => req.result_file_url != null);

  //get latest versions
  if (ver.length > 0) {
    current_version = ver.reduce((a, b, i) => {
      return new Date(a.delivery_date.replace(/-/g,"/")) > new Date(b.delivery_date.replace(/-/g,"/")) ? a : b;
    });
  }

  //get old versions
  ver = ver.sort(
    (a, b) => Date.parse(b.delivery_date) - Date.parse(a.delivery_date)
  );
  ver.shift();
  prev_versions = ver;

  return {
    current_version: current_version,
    prev_versions: prev_versions,
  };
};
